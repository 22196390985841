const equalHeight = (() => {
  const heightEqualizer = () => {
    let collectionElementsToEqual = $('.stat3up .stat3up__number');
    collectionElementsToEqual.css('height', 'auto');
    let arrLength = collectionElementsToEqual.length;

    for ( let i = 0; i < arrLength - 1; i++ ) {
      for ( let j = 1; j < arrLength; j++ ) {
        if( $(collectionElementsToEqual[i]).offset().top == $(collectionElementsToEqual[j]).offset().top ) {
          if ( $(collectionElementsToEqual[i]).innerHeight() < $(collectionElementsToEqual[j]).innerHeight() ) {
            $(collectionElementsToEqual[i]).css('height', $(collectionElementsToEqual[j]).innerHeight() );
          } else {
            $(collectionElementsToEqual[j]).css('height', $(collectionElementsToEqual[i]).innerHeight() );
          }
        }
      }
    }
  }

  const init = () => {
    heightEqualizer();

    let timeout;

    $(window).on('resize', () => {
      if(Boolean(timeout))
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        heightEqualizer();
      }, 100);
    });
  }

  return {
    init,
  }
})();

export default equalHeight;