/**
 * Alert display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if alert present
 */

const alert = (() => {
  // helps account for the header having fixed positioning on page load
  function fixHeaderPositioning() {
    const header = $('header');
    const alerts = document.querySelectorAll('.alert');
    let headerOffset = 0;

    alerts.forEach((a) => {
      headerOffset += a.offsetHeight;
    });

    $(window).on('scroll load', () => {
      if (window.scrollY > headerOffset) {
        header.removeClass('js-alert-positioned');
      } else {
        header.addClass('js-alert-positioned');
      }
    });
  }

  const init = () => {
    if (!$('.alert').length) {
      return;
    }

    $('document').ready(fixHeaderPositioning);
  };

  return {
    init,
  };
})();

export default alert;
