/**
 * Home Hero display object interactions
 * @param {*} $
 * @returns {init} Determine and run if home hero present
 */

const homeHero = (() => {
  function toggleVideoPlayback(e) {
    const clicked = e.target;
    const hero = clicked.parentElement.parentElement.parentElement;
    const video = hero.querySelectorAll('.hero__video')[0];

    hero.classList.toggle('js-paused');

    if (hero.classList.contains('js-paused')) {
      video.pause();
      $('.hero__controller').find('span').text('Play Video');
    } else {
      video.play();
      $('.hero__controller').find('span').text('Pause Video');
    }
  }

  function initializeVideo() {
    const video = document.querySelectorAll('.hero__video')[0];

    video.controls = false;
  }

  const init = () => {
    if (!$('.hero--home .hero__video').length) {
      return;
    }

    initializeVideo();
    $('.hero__controller').on('click', toggleVideoPlayback);
  };

  return {
    init,
  };
})();

export default homeHero;
