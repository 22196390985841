const footerDropdown = (() => {
  const init = () => {
    const OPENED_CLASS_NAME = 'is-opened';
    const ANIMATION_SPEED = 300;

    $('.links-dropdown').each((index, dropdown) => {
      const $dropdown = $(dropdown);
      const $opener = $dropdown.find('.links-dropdown__opener');
      const $list = $dropdown.find('.links-dropdown__list');

      $opener.on('click', () => {
        if (!$dropdown.hasClass(OPENED_CLASS_NAME)) {
          $dropdown.addClass(OPENED_CLASS_NAME);
          $opener.attr('aria-expanded', 'true');
          $list
            .stop()
            .slideDown(ANIMATION_SPEED)
            .attr('aria-hidden', 'false');
        } else {
          $dropdown.removeClass(OPENED_CLASS_NAME);
          $opener.attr('aria-expanded', 'false');
          $list
            .stop()
            .slideUp(ANIMATION_SPEED)
            .attr('aria-hidden', 'true');
        }
      });
    });
  }

  return {
    init,
  }
})();

export default footerDropdown;