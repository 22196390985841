const faqCarousel = (() => {
  const initModalKeyNav = ($content, $closeBtn) => {
    const $lastFocusable = $content
      .find('.answer-holder a, .answer-holder button')
      .last();

    $lastFocusable.on('keydown', (e) => {
      if ((e.keyCode === 9) & !e.shiftKey) {
        e.preventDefault();
        $closeBtn.focus();
      }
    });

    $closeBtn.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault();
        $lastFocusable.focus();
      }
    });
  };

  const initModal = () => {
    const MODAL_VISIBLE_CLASS_NAME = 'faq-modal-visible';
    const BODY_FIXED_CLASS_NAME = 'js-fixed';
    const $bodyHtml = $('html,body');
    const $modal = $('.questions--mobile .answer-modal');

    const $modalOpeners = $(
      '.questions--mobile .questions__item-content-inner',
    );

    $modalOpeners.each((index, item) => {
      const $currentOpener = $(item);

      $currentOpener.on('click', () => {
        const $modalContent = $currentOpener
          .closest('.questions__item-content-inner')
          .clone();
        const $question = $modalContent.find('.questions__question');
        const LABEL_ID = $question.attr('id');
        $modal.attr('aria-labelledby', LABEL_ID);
        $question.replaceWith(
          '<div class="questions__question">' + $question.html() + '</div>',
        );

        $modal.append($modalContent);
        $currentOpener.addClass(MODAL_VISIBLE_CLASS_NAME);
        $bodyHtml.addClass(
          `${MODAL_VISIBLE_CLASS_NAME} ${BODY_FIXED_CLASS_NAME}`,
        );

        const $closeBtn = $modalContent.find('.close-faq-modal');

        setTimeout(() => {
          $closeBtn[0].focus();
        }, 100);

        $closeBtn.on('click', () => {
          const questionOpenerToFocus = $currentOpener
            .get(0)
            .querySelectorAll('.questions__question')[0];
          $currentOpener.removeClass(MODAL_VISIBLE_CLASS_NAME);
          $bodyHtml.removeClass(
            `${MODAL_VISIBLE_CLASS_NAME} ${BODY_FIXED_CLASS_NAME}`,
          );

          setTimeout(() => {
            questionOpenerToFocus.focus();
            $modal.find('.questions__item-content-inner').remove();
            $modal.removeAttr('aria-labelledby');
          }, 300);
        });

        initModalKeyNav(
          $modal.find('.questions__item-content-inner'),
          $closeBtn,
        );
      });
    });
  };

  const initCarousel = () => {
    const $faqCarouselTop = $('.questions--carousel.questions-top');
    const $faqCarouselBottom = $('.questions--carousel.questions-bottom');

    $faqCarouselTop.slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      autoplay: false,
      swipe: false,
    });

    $faqCarouselBottom.slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      autoplay: false,
      swipe: false,
    });

    const $faqCarouselTopButton = $faqCarouselTop.find('.slick-next');
    const $faqCarouselBottomButton = $faqCarouselBottom.find('.slick-next');

    $faqCarouselTopButton.on('click', () => {
      $faqCarouselBottom.slick('slickPrev');
    });

    $faqCarouselBottomButton.on('click', () => {
      $faqCarouselTop.slick('slickPrev');
    });
  };

  const init = () => {
    initCarousel();
    initModal();
  };

  return {
    init,
  };
})();

export default faqCarousel;
