/**
 * Blog List display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Blog List is present
 */

const blogList = (() => {
  function openMenu(elem) {
    const dropdown = $(elem).next();
    elem.attr('aria-expanded', 'true');
    dropdown.attr('aria-hidden', 'false');
    dropdown.slideDown(300);
  }

  function closeMenu(elem) {
    const dropdown = $(elem).next();
    elem.attr('aria-expanded', 'false');
    dropdown.attr('aria-hidden', 'true');
    dropdown.slideUp(300);
  }

  function render() {
    const bttn = $('.blog__filter__bttn');

    bttn.on('click', (e) => {
      closeMenu(
        $(`.blog__filter__bttn[aria-expanded="true"]`).not($(e.target)),
      );

      if (e.target.getAttribute('aria-expanded') === 'false') {
        openMenu($(e.target));
      } else {
        closeMenu($(e.target));
      }
    });

    // If clicked outside of dropdown and dropdown is open, close it
    $(document).on('click', (e) => {
      if (
        !$(e.target).closest('.blog__categories, .blog__archive').length &&
        $('.blog__filter__dropdown').is(':visible')
      ) {
        $('.blog__filter__bttn').each((i, elem) => {
          closeMenu($(elem));
        });
      }
    });
  }

  const init = () => {
    if (!$('.blog--listing').length) {
      return;
    }

    render();
  };

  return {
    init,
  };
})();

export default blogList;
