/* global Vue */

/**
 * Stackable Credentials display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Stackable Credentials is present
 */

const stackableCredentials = (() => {
  function vueRender() {
    const { createApp } = Vue;

    createApp({
      data() {
        return {
          credsArr: [],
          zoomIncrementCount: 0.1,
          zoomCurrent: 1,
          zoomMin: 0.5,
          zoomMax: 1.5,
          scrollPercentageInterval: 0.2,
        };
      },

      created() {
        const dataUrl = document
          .getElementById('stackable-credentials-app')
          .getAttribute('data-url');

        $.getJSON(dataUrl, (json) => {
          this.credsArr = json;
        });
      },

      mounted() {
        this.setMountedEvents();
      },

      template: `
        <div class="stackable-credentials__wrapper">
          <div class="stackable-credentials-controller">
            <div class="stackable-credentials-controller__top">
              <button class="stackable-credentials-controller__modal-bttn open" @click="modalToggleClose($event)"><span class="visually-hidden">Close Dialog</span></button>
            </div>

            <div class="stackable-credentials-controller__bottom">
              <div class="stackable-credentials-controller__scroll">
                <button class="stackable-credentials-controller__scroll-bttn stackable-credentials-controller__scroll-bttn--up" @click="scroll($event, 'up')"><span class="visually-hidden">Scroll Up</span></button>
                <button class="stackable-credentials-controller__scroll-bttn stackable-credentials-controller__scroll-bttn--right" @click="scroll($event, 'right')"><span class="visually-hidden">Scroll Right</span></button>
                <button class="stackable-credentials-controller__scroll-bttn stackable-credentials-controller__scroll-bttn--down" @click="scroll($event, 'down')"><span class="visually-hidden">Scroll Down</span></button>
                <button class="stackable-credentials-controller__scroll-bttn stackable-credentials-controller__scroll-bttn--left" @click="scroll($event, 'left')"><span class="visually-hidden">Scroll Left</span></button>
              </div>
            </div>
          </div>
          <div class="stackable-credentials__expand">
            <a href="#" @click="modalToggleOpen($event)" class="cta cta--button">Explore</a>
          </div>
          <div class="stackable-credentials__wrapper-inner" aria-hidden="true">
            <div class="stackable-credentials__lines">
              <div class="stackable-credentials__line">
                <span>Short Term</span>
              </div>
              <div class="stackable-credentials__line">
                <span>Long Term</span>
              </div>
              <div class="stackable-credentials__line">
                <span>Degree</span>
              </div>
            </div>
            <ul class="degree-trail degree-trail--level-1">
              <li v-for="(item, index) in credsArr">
                <div class="degree-wrapper">
                  <div class="card degree">
                    <p class="card__title">{{ item.title }}</p>

                    <div class="card__bottom">
                      <div class="card__creds-wrapper">
                        <div v-for="(item, index) in item.creds" class="card__creds-item" :class="item.toLowerCase().split(' ').join('-')">{{ item }}</div>
                      </div>

                      <div class="card__duration">
                        {{ item.duration }}
                      </div>
                    </div>
                  </div>

                  <template v-if="Object.keys(credsArr[0].additionalDegree).length">
                    <div class="card degree additionalDegree">
                      <p class="card__title">{{ credsArr[0].additionalDegree.title }}</p>

                      <div class="card__bottom">
                        <div class="card__creds-wrapper">
                          <div v-for="(item, index) in credsArr[0].additionalDegree.creds" class="card__creds-item" :class="item.toLowerCase().split(' ').join('-')">{{ item }}</div>
                        </div>

                        <div class="card__duration">
                          {{ credsArr[0].additionalDegree.duration }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>

                <ul class="degree-trail degree-trail--level-2" :class="{ single: credsArr[index].certificates.length == 1 }">
                  <li class="bracket"></li>
                  <li v-for="(item, index) in credsArr[index].certificates">
                    <div class="card credential">
                      <p class="card__title">{{ item.title }}</p>

                      <div class="card__bottom">
                        <div class="card__creds-wrapper">
                          <div v-for="(item, index) in item.creds" class="card__creds-item" :class="item.toLowerCase().split(' ').join('-')">{{ item }}</div>
                        </div>

                        <div class="card__duration">
                          {{ item.duration }}
                        </div>
                      </div>
                    </div>

                    <ul class="degree-trail degree-trail--level-3"  :class="{ single: item.certificates.length == 1 }">
                      <li class="bracket"></li>
                      <li v-for="(item, index) in item.certificates">
                      <div class="card credential">
                        <p class="card__title">{{ item.title }}</p>

                        <div class="card__bottom">
                          <div class="card__creds-wrapper">
                            <div v-for="(item, index) in item.creds" class="card__creds-item" :class="item.toLowerCase().split(' ').join('-')">{{ item }}</div>
                          </div>

                          <div class="card__duration">
                            {{ item.duration }}
                          </div>
                        </div>
                      </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      `,

      methods: {
        setBracketPositions() {
          $('.stackable-credentials .bracket').each((i, elem) => {
            const listItemFirst = $(elem)
              .parent()
              .children('li')
              .first()
              .next();
            const listItemLast = $(elem).parent().children('li').last();
            const cardFirst = listItemFirst.children('.card').eq(0);
            const cardLast = listItemLast.children('.card').eq(0);
            const cardOffsetFirst =
              cardFirst.offset().top -
              cardFirst.parent().offset().top +
              cardFirst.innerHeight() / 2;
            const cardOffsetLast =
              cardLast.offset().top -
              cardLast.parent().offset().top +
              cardLast.innerHeight() / 2;

            $(elem).css({
              top: cardOffsetFirst,
              height: `calc(100% - ${cardOffsetFirst + cardOffsetLast}px)`,
            });
          });
        },

        setControllerVisibilityLargeScreens() {
          $.fn.isHScrollable = function () {
            return this[0].scrollWidth > this[0].clientWidth;
          };

          $.fn.isVScrollable = function () {
            return this[0].scrollHeight > this[0].clientHeight;
          };

          $.fn.isScrollable = function () {
            return (
              this[0].scrollWidth > this[0].clientWidth ||
              this[0].scrollHeight > this[0].clientHeight
            );
          };

          const isScrollable = $(
            '.stackable-credentials__wrapper-inner',
          ).isScrollable();

          if (isScrollable) {
            $('.stackable-credentials').addClass(
              'stackable-credentials--controller-visible-large',
            );
          } else {
            $('.stackable-credentials').removeClass(
              'stackable-credentials--controller-visible-large',
            );

            // If space is available for 1X zoom after resize, reset scaling
            this.zoomCurrent = 1;

            this.rescaleLines();

            $('.stackable-credentials__wrapper-inner > .degree-trail').css({
              transform: `scale(${this.zoomCurrent})`,
            });
          }

          if (
            $(window).width() <= 1024 &&
            !$('.stackable-credentials').hasClass(
              'stackable-credentials--modal-open',
            )
          ) {
            $('.stackable-credentials__wrapper-inner').attr(
              'aria-hidden',
              'true',
            );
          } else {
            $('.stackable-credentials__wrapper-inner').attr(
              'aria-hidden',
              'false',
            );
          }

          if ($(window).width() > 1024 &&
            $('.stackable-credentials').hasClass(
              'stackable-credentials--modal-open',
            )) {
              console.log('here');
            $('.stackable-credentials__wrapper-inner').attr(
              'aria-hidden',
              'true',
            );
            $('.stackable-credentials-controller__modal-bttn').removeClass(
              'open',
            );
            $('.stackable-credentials').removeClass(
              'stackable-credentials--modal-open',
            );
            $('html, body').removeClass('js-fixed');

            $('.stackable-credentials__wrapper').removeAttr('role', 'dialog');
            $('.stackable-credentials__wrapper').removeAttr(
              'aria-labelledby',
              'stackable-creds-dialog-label',
            );
          }
        },

        setLinesWidthHeight() {
          $('.stackable-credentials__lines').css({
            width: $('.degree-trail--level-1').width(),
            height: $('.degree-trail--level-1').outerHeight(),
          });
        },

        setMountedEvents() {
          setTimeout(() => {
            this.setBracketPositions();
            this.setControllerVisibilityLargeScreens();
            this.setLinesWidthHeight();

            $(window).on('resize', this.setBracketPositions);
            $(window).on('resize', this.setControllerVisibilityLargeScreens);
            $(window).on('resize', this.setLinesWidthHeight);

            const tabOne = $('.stackable-credentials-controller__modal-bttn');
            const tabTwo = $(
              '.stackable-credentials-controller__scroll-bttn--left',
            );

            tabOne.on('keydown', (e) => {
              if (e.keyCode === 9 && e.shiftKey) {
                e.preventDefault();
                tabTwo.focus();
              }
            });

            tabTwo.on('keydown', (e) => {
              if (e.keyCode === 9 && !e.shiftKey) {
                e.preventDefault();
                tabOne.focus();
              }
            });
          }, 300);
        },

        // All interface methods

        modalToggleOpen(e) {
          e.preventDefault();
          $('.stackable-credentials-controller__modal-bttn').addClass('open');
          $('.stackable-credentials').addClass(
            'stackable-credentials--modal-open',
          );
          $('.stackable-credentials__wrapper-inner').attr(
            'aria-hidden',
            'false',
          );
          $('.stackable-credentials__wrapper').attr('role', 'dialog');
          $('.stackable-credentials__wrapper').attr(
            'aria-labelledby',
            'stackable-creds-dialog-label',
          );
          $('html, body').addClass('js-fixed');
          $('.stackable-credentials-controller__modal-bttn').focus();
        },

        modalToggleClose(e) {
          e.preventDefault();
          $('.stackable-credentials__wrapper-inner').attr(
            'aria-hidden',
            'true',
          );
          $('.stackable-credentials-controller__modal-bttn').removeClass(
            'open',
          );
          $('.stackable-credentials').removeClass(
            'stackable-credentials--modal-open',
          );
          $('html, body').removeClass('js-fixed');

          $('.stackable-credentials__wrapper').removeAttr('role', 'dialog');
          $('.stackable-credentials__wrapper').removeAttr(
            'aria-labelledby',
            'stackable-creds-dialog-label',
          );
          $('.stackable-credentials__expand > a').focus();
        },

        rescaleLines() {
          $('.stackable-credentials__lines').removeAttr('style');
          this.setLinesWidthHeight();
          let style = $('.stackable-credentials__lines').attr('style');
          style += `transform: scale(${this.zoomCurrent});`;
          $('.stackable-credentials__lines').attr('style', style);
        },

        zoomIn(e) {
          const content = $(
            '.stackable-credentials__wrapper-inner > .degree-trail',
          );

          if (!$(e.target).hasClass('disabled')) {
            this.zoomCurrent += this.zoomIncrementCount;
            this.zoomCurrent = Math.round(10 * this.zoomCurrent) / 10;
            content.removeAttr('style');

            this.rescaleLines();

            content.css({ transform: `scale(${this.zoomCurrent})` });
          }
        },

        zoomOut(e) {
          const content = $(
            '.stackable-credentials__wrapper-inner > .degree-trail',
          );
          if (!$(e.target).hasClass('disabled')) {
            this.zoomCurrent -= this.zoomIncrementCount;
            this.zoomCurrent = Math.round(10 * this.zoomCurrent) / 10;
            content.removeAttr('style');
            this.rescaleLines();

            content.css({ transform: `scale(${this.zoomCurrent})` });
          }
        },

        scroll(e, direction) {
          const container = $('.stackable-credentials__wrapper-inner');
          const contanerScrollInterval =
            container[0].scrollWidth * this.scrollPercentageInterval;

          if (direction === 'up') {
            container.animate(
              {
                scrollTop: `-=${contanerScrollInterval}`,
              },
              300,
              'swing',
            );
          } else if (direction === 'right') {
            container.animate(
              {
                scrollLeft: `+=${contanerScrollInterval}`,
              },
              300,
              'swing',
            );
          } else if (direction === 'down') {
            container.animate(
              {
                scrollTop: `+=${contanerScrollInterval}`,
              },
              300,
              'swing',
            );
          } else if (direction === 'left') {
            container.animate(
              {
                scrollLeft: `-=${contanerScrollInterval}`,
              },
              300,
              'swing',
            );
          }
        },
      },
    }).mount('#stackable-credentials-app');
  }

  const init = () => {
    if (!$('.stackable-credentials').length) {
      return;
    }

    vueRender();
  };

  return {
    init,
  };
})();

export default stackableCredentials;
