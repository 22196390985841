const mainSearch = (() => {
  let $body;
  let $htmlBody;

  let $mainNavOpener;
  let $mainNavHolder;
  let $mainSearchOpener;
  let $mainSearchClose;
  let $mainSearchForm;
  let $mainSearchInput;
  let $mainSearchSubmit;

  const MAIN_NAV_OPENED_CLASS_NAME = 'main-nav-opened';
  const MAIN_SEARCH_OPENED_CLASS_NAME = 'main-search-opened';

  const setGlobalVariables = () => {
    $body = $('html > body');
    $htmlBody = $('html, html > body');
    $mainNavOpener = $('.main-nav-opener');
    $mainNavHolder = $('.nav__holder');
    $mainSearchOpener = $('.search__opener');
    $mainSearchClose = $('.search__close');
    $mainSearchForm = $('.search__form');
    $mainSearchInput = $('.search__form .search__input');
    $mainSearchSubmit = $('.search__submit');
  }

  const initMainSearchOpener = () => {
    const toggleSearch = () => {
      if (!$body.hasClass(MAIN_SEARCH_OPENED_CLASS_NAME)) {
        $htmlBody.addClass(MAIN_SEARCH_OPENED_CLASS_NAME);
        $mainSearchOpener.attr('aria-expanded', 'true');
        $mainSearchClose.attr('aria-expanded', 'true');
        $mainSearchForm.attr('aria-hidden', 'false');

        setTimeout(() => {
          $mainSearchInput.focus();
        }, 1000);
      } else {
        $htmlBody.removeClass(MAIN_SEARCH_OPENED_CLASS_NAME);
        $mainSearchOpener.attr('aria-expanded', 'false');
        $mainSearchClose.attr('aria-expanded', 'false');
        $mainSearchForm.attr('aria-hidden', 'true');
      }
    }

    $mainSearchOpener.on('click', () => {
      if ($body.hasClass(MAIN_NAV_OPENED_CLASS_NAME)) {
        $htmlBody.removeClass(MAIN_NAV_OPENED_CLASS_NAME);
        $mainNavOpener.attr('aria-expanded', 'false')
          .find('.text')
          .text('Menu');
        $mainNavHolder.attr('aria-hidden', 'true');

        setTimeout(() => {
          toggleSearch();
        }, 50)
      } else {
        toggleSearch();
      }
    });

    $mainSearchClose.on('click', () => {
      toggleSearch();
    });
  }

  const initSearchKeyboardControls = () => {
    $mainSearchSubmit.on('keydown', (e) => {
      if (e.keyCode === 9 && !e.shiftKey && $body.hasClass(MAIN_SEARCH_OPENED_CLASS_NAME)) {
        e.preventDefault();
        $mainSearchClose.focus();
      }
    });

    $mainSearchClose.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault();
        $mainSearchSubmit.focus();
      }
    });

    $(document).on('keydown', (e) => {
      if (e.keyCode === 27) {
        $htmlBody.removeClass(MAIN_SEARCH_OPENED_CLASS_NAME);
        $mainSearchOpener.attr('aria-expanded', 'false');
        $mainSearchClose.attr('aria-expanded', 'false');
        $mainSearchForm.attr('aria-hidden', 'true');
      }
    });
  };


  const init = () => {
    setGlobalVariables();
    initMainSearchOpener();
    initSearchKeyboardControls();
  };

  return {
    init,
  };
})();

export default mainSearch;
