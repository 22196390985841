/**
 * Program Accordion display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Program Accordion is present
 */

const programAccordion = (() => {
  // Expand-all toggle functionality.
  function accordionExpandAll() {
    const accordionItems = $(this).parent().siblings('table').find('tbody tr');

    $(accordionItems)
      .children('.program-accordion__bttn')
      .attr('aria-expanded', 'true');
    $(accordionItems)
      .find('.program-accordion__bttn')
      .attr('aria-expanded', true);
    $(accordionItems).find('.program-accordion__body').slideDown(300);
    $(accordionItems).addClass('content-expanded');
    $(this)
      .addClass('js-disabled')
      .attr('aria-expanded', 'true')
      .prop('disabled', true);
    $(this)
      .siblings('.program-accordion__collapse-all')
      .removeClass('js-disabled')
      .removeAttr('aria-expanded')
      .prop('disabled', false);
  }

  // Collapse-all toggle functionality.
  function accordionCollapseAll() {
    const accordionItems = $(this).parent().siblings('table').find('tbody tr');

    $(accordionItems).removeClass('content-expanded');
    $(accordionItems)
      .children('.program-accordion__bttn')
      .attr('aria-expanded', 'false');
    $(accordionItems)
      .find('.program-accordion__bttn')
      .removeClass('js-expanded')
      .attr('aria-expanded', 'false');
    $(accordionItems)
      .find('.program-accordion__body')
      .slideUp(300)
      .prop('disabled', true);
    $(this)
      .addClass('js-disabled')
      .attr('aria-expanded', 'false')
      .prop('disabled', true);
    $(this)
      .siblings('.program-accordion__expand-all')
      .removeClass('js-disabled')
      .removeAttr('aria-expanded')
      .prop('disabled', false);
  }
  function render() {
    const bttn = $('.program-accordion__bttn');

    bttn.on('click', (e) => {
      const dropdown = $(e.target).next();

      if (e.target.getAttribute('aria-expanded') === 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        e.target.querySelector('span').innerHTML = 'Close Accordion';
        e.target.closest('tr').classList.add('content-expanded');
        dropdown.attr('aria-hidden', 'false');
        dropdown.slideDown(300);
      } else {
        e.target.setAttribute('aria-expanded', 'false');
        e.target.querySelector('span').innerHTML = 'Open Accordion';
        e.target.closest('tr').classList.remove('content-expanded');
        dropdown.attr('aria-hidden', 'true');
        dropdown.slideUp(300);
      }
    });

    // Add mobile labels
    document.querySelectorAll('.program-accordion').forEach((elem, i) => {
      const tableHeader = elem.querySelector('.table-head > tr');
      const tableData = elem.querySelectorAll('.table-body > tr');

      for (let a = 0; a < tableData.length; a++) {
        for (let b = 0; b < tableData[a].querySelectorAll('td').length; b++) {
          const headerLabel = tableHeader
            .querySelectorAll('th')
            [b].querySelector('span').innerHTML;
          const mobileLabel = document.createElement('span');
          mobileLabel.className = 'label-mobile';
          mobileLabel.innerText = headerLabel;
          tableData[a].querySelectorAll('td')[b].prepend(mobileLabel);
        }
      }
    });
  }

  const init = () => {
    if (!$('.program-accordion').length) {
      return;
    }

    render();
    $('.program-accordion__expand-all').on('click', accordionExpandAll);
    $('.program-accordion__collapse-all').on('click', accordionCollapseAll);
  };

  return {
    init,
  };
})();

export default programAccordion;
