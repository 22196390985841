const faqSupportCarousel = (() => {
  const init = () => {
    const $authorCarousels = $('.author-carousel');

    $authorCarousels.each((index, carousel) => {
      const $carousel = $(carousel);

      $carousel.slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        autoplay: true,
        swipe: true,
        arrows: false,
      });

      const $pauseButton = $carousel.find('.slick-autoplay-toggle-button');

      $pauseButton.on('click', () => {
        $pauseButton.toggleClass('is-paused');
      });
    });
  };

  return {
    init,
  }
})();

export default faqSupportCarousel;