/**
 * Ribbon carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if ribbon carousel present
 */

const ribbonCarousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.ribbon-carousel__wrapper').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    });
  }

  function itemHoverIn(e) {
    const $item = $(e.target).closest('.ribbon-carousel__item');
    $item.addClass('js-hover');
  }

  function itemHoverOut(e) {
    const $item = $(e.target).closest('.ribbon-carousel__item');
    $item.removeClass('js-hover');
  }

  const init = () => {
    if (!$('.ribbon-carousel').length) {
      return;
    }

    renderCarousel();

    $('.ribbon-carousel__link').hover(itemHoverIn, itemHoverOut);
  };

  return {
    init,
  };
})();

export default ribbonCarousel;
