import '@babel/polyfill';
import '@accessible360/accessible-slick';
import 'object-fit-images';
import 'jquery-touchswipe';

import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import facultyTable from './object/faculty-table';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import catalog from './object/catalog';
import tableScroll from './object/scroll-table';
import tabs from './object/tabs';
import mainHeader from './object/main-header';
import mainSearch from './object/main-search';
import alert from './object/alert';
import adjustableColumns from './object/adjustable-columns';
import programAccordion from './object/program-accordion';
import ribbonCarousel from './object/ribbon-carousel';
import footerDropdown from './object/footer-dropdown';
import videoCarousel from './object/video-carousel';
import programsFilter from './vendor/programs-filter';
import areasOfInterest from './object/areas-of-interest';
import sectionNavigation from './object/section-nav';
import homeHero from './object/home-hero';
import newsEmbed from './object/news-embed';
import blog3Up from './object/blog3up';
import stickyCTAs from './object/sticky-ctas';
import stackableCredentials from './object/stackable-credentials';
import faqCarousel from './object/faq-carousel';
import faqSupportCarousel from './object/faq-support-carousel';
import faqDropdowns from './object/faq-dropdowns';
import blogList from './object/blog-list';

import lastWord from './util/last-word';
import scrollTrigger from './util/scroll-trigger';
import focusTriggersScrollEvent from './util/focus-triggers-scroll-event';
import equalHeight from './util/equalHeight';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  accordion.init();
  inlineVideo.init();
  instagram.init();
  twitterLoader.init();
  facultyTable.init();
  calendar.init();
  catalogProgram.init();
  carousel.init();
  programFinder.init();
  catalog.init();
  tableScroll.init();
  tabs.init();
  mainHeader.init();
  mainSearch.init();
  alert.init();
  adjustableColumns.init();
  programAccordion.init();
  ribbonCarousel.init();
  footerDropdown.init();
  videoCarousel.init();
  programsFilter.init();
  areasOfInterest.init();
  sectionNavigation.init();
  homeHero.init();
  newsEmbed.init();
  stickyCTAs.init();
  stackableCredentials.init();
  faqCarousel.init();
  faqSupportCarousel.init();
  faqDropdowns.init();
  blog3Up.init();
  blogList.init();

  lastWord.init();
  scrollTrigger.init();
  focusTriggersScrollEvent.init();
  equalHeight.init();

  objectFitImages();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
    }, 100);
  });
});
