/**
 * Tabs display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if tabs present
 */

const tabs = (() => {
  function toggleTab(e) {
    const selectedTab = e.target;
    const selectedTabPanel = document.getElementById(
      selectedTab.getAttribute('aria-controls'),
    );
    const currentActiveTab = document.querySelectorAll(
      `.tab__button[aria-selected="true"]`,
    )[0];
    const currentActiveTabPanel = document.querySelectorAll(
      `.tab__panel.js-selected`,
    )[0];

    if (selectedTab.getAttribute('aria-selected') === 'false') {
      selectedTab.setAttribute('aria-selected', 'true');
      selectedTabPanel.classList.add('js-selected');
      currentActiveTab.setAttribute('aria-selected', 'false');
      currentActiveTabPanel.classList.remove('js-selected');
    }
  }

  const init = () => {
    if (!$('.tabs').length) {
      return;
    }

    $('.tab__button').on('click', toggleTab);
  };

  return {
    init,
  };
})();

export default tabs;
