/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  // Render carousel
  function renderCarousel() {
    const VISIBLE_SLIDE_CLASS_NAME = 'is-visible';
    const nextBtnTemplate = `<button class="slick-next slick-arrow slick-arrow--custom" type="button"><span class="slick-sr-only">Next</span><span class="slick-arrow__icon" aria-hidden="true"></span></button>`;
    const prevBtnTemplate = `<button class="slick-prev slick-arrow slick-arrow--custom" type="button"><span class="slick-sr-only">Previous</span><span class="slick-arrow__icon" aria-hidden="true"></span></button>`;

    $('.carousel__wrapper').each((index, carousel) => {
      const $carousel = $(carousel);

      const customSlickPrev = () => {
        const currentActiveNavButton = document.querySelectorAll(
          '.carousel__dots button[aria-current]',
        )[0];

        $carousel
          .find('.slick-slide')
          .removeClass(VISIBLE_SLIDE_CLASS_NAME)
          .find('.carousel__caption')
          .fadeOut(300);

        setTimeout(
          () => {
            currentActiveNavButton.removeAttribute('aria-current');
            $carousel.slick('slickPrev');

            const newSlideIndex = $carousel.slick('slickCurrentSlide');
            const newActiveNavButton = document.querySelectorAll(
              `.carousel__dots button[data-corresponding-slide="${newSlideIndex}"]`,
            )[0];

            newActiveNavButton.setAttribute('aria-current', 'true');
          },
          $(window).width() < 700 ? 300 : 600,
        );
      };

      const customSlickNext = () => {
        const currentActiveNavButton = document.querySelectorAll(
          '.carousel__dots button[aria-current]',
        )[0];

        $carousel
          .find('.slick-slide')
          .removeClass(VISIBLE_SLIDE_CLASS_NAME)
          .find('.carousel__caption')
          .fadeOut(300);

        setTimeout(
          () => {
            currentActiveNavButton.removeAttribute('aria-current');
            $carousel.slick('slickNext');

            const newSlideIndex = $carousel.slick('slickCurrentSlide');
            const newActiveNavButton = document.querySelectorAll(
              `.carousel__dots button[data-corresponding-slide="${newSlideIndex}"]`,
            )[0];

            newActiveNavButton.setAttribute('aria-current', 'true');
          },
          $(window).width() < 700 ? 300 : 600,
        );
      };

      const setControlsPosition = () => {
        let controlsTopPosition;

        if ($(window).width() < 700) {
          controlsTopPosition =
            $carousel.find('.slick-active .carousel__img').outerHeight() / 2;
        } else {
          controlsTopPosition =
            $carousel.find('.carousel__img').first().outerHeight() / 2;
        }

        $carousel.css({ '--controls-top': `${controlsTopPosition}px` });
      };

      const addCustomControls = () => {
        const $btnNext = $(nextBtnTemplate);
        const $btnPrev = $(prevBtnTemplate);

        $btnNext.on('click touchstart', (e) => {
          customSlickNext();
        });

        $btnPrev.on('click touchstart', (e) => {
          customSlickPrev();
        });

        $carousel.append($btnNext);
        $carousel.prepend($btnPrev);
      };

      // add swipe eventListeners to carousel container
      $carousel.swipe({
        //Single swipe handler for left swipes
        swipeLeft: function (
          event,
          direction,
          distance,
          duration,
          fingerCount,
        ) {
          customSlickNext();
        },
        swipeRight: function (
          event,
          direction,
          distance,
          duration,
          fingerCount,
        ) {
          customSlickPrev();
        },
        //Default is 75px, set to 0 for demo so any distance triggers swipe
        threshold: 0,
      });

      $carousel.on('init', function (slick) {
        $(slick.target)
          .find('.slick-current')
          .addClass(VISIBLE_SLIDE_CLASS_NAME)
          .find('.carousel__caption')
          .show();

        setControlsPosition();
      });

      $(window).on('resize', () => {
        setControlsPosition();
      });

      $carousel.slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        autoplay: false,
        centerMode: true,
        variableWidth: true,
        swipe: false,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: false,
              infinite: true,
              speed: 300,
              slidesToShow: 1,
              centerMode: false,
              variableWidth: false,
              swipe: false,
              arrows: false,
            },
          },
        ],
      });

      addCustomControls();

      $carousel.on('breakpoint', function () {
        if (!$carousel.find('.slick-arrow--custom').length) {
          addCustomControls();
        }
      });

      $('.carousel__wrapper').on(
        'afterChange',
        function (event, slick, currentSlide) {
          $(slick.$slides[currentSlide]).addClass(VISIBLE_SLIDE_CLASS_NAME);

          setTimeout(
            () => {
              $(slick.$slides[currentSlide])
                .find('.carousel__caption')
                .fadeIn(300);
            },
            $(window).width() < 700 ? 0 : 600,
          );
        },
      );

      $('.carousel__dots button').on('click', (e) => {
        const clicked = e.target;
        const selectedIndex = clicked.dataset.correspondingSlide;
        const currentActiveNavButton = document.querySelectorAll(
          '.carousel__dots button[aria-current]',
        )[0];
        const currentSlide = document.querySelectorAll(
          '.slick-slide.is-visible',
        )[0];
        const currentSlideIndex = currentSlide.dataset.slickIndex;

        if (selectedIndex === currentSlideIndex) {
          return;
        }

        $carousel
          .find('.slick-slide')
          .removeClass(VISIBLE_SLIDE_CLASS_NAME)
          .find('.carousel__caption')
          .fadeOut(300);

        setTimeout(
          () => {
            currentActiveNavButton.removeAttribute('aria-current');
            $carousel.slick('slickGoTo', selectedIndex);
            clicked.setAttribute('aria-current', 'true');
          },
          $(window).width() < 700 ? 300 : 600,
        );
      });
    });
  }

  function customSlickDotsTransition(e) {
    const currentSlide = document.querySelectorAll(
      '.slick-slide.is-visible',
    )[0];
    const currentSlideIndex = currentSlide.dataset.slickIndex;
    const selectedIndex = e.target.dataset.correspondingSlide;

    if (currentSlideIndex === selectedIndex) {
      return;
    }

    console.log(currentSlideIndex);
    console.log(selectedIndex);
    console.log($carousel);
  }

  const init = () => {
    if (!$('.carousel').length) {
      return;
    }

    renderCarousel();
    // $('.carousel__dots button').on('click', customSlickDotsTransition);
  };

  return {
    init,
  };
})();

export default carousel;
