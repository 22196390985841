const adjustableColumns = (() => {
  const init = () => {
    if (!$('.adjustable-columns').length) {
      return;
    }

    $('.adjustable-columns').each((i, elem) => {
      const $columnCount = $(elem)
        .find('.adjustable-columns__items')
        .children().length;

      if ($columnCount % 2) {
        $(elem).addClass('adjustable-columns--odd');
      } else {
        $(elem).addClass('adjustable-columns--even');
      }
    });
  };

  return {
    init,
  };
})();

export default adjustableColumns;
