/**
 * Areas of Interest display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if areas of interest present
 */

const areasOfInterest = (() => {
  // sets exact height of .slide__top in px to ensure slide transition is smooth
  function setSlideTopHeight() {
    const slideTops = document.querySelectorAll(
      '.slick-slide:not(.slick-cloned) .slide__top',
    );

    slideTops.forEach((slideTop) => {
      const parentSlide = slideTop.closest('.slick-slide');
      const slideTitleHeight =
        slideTop.querySelectorAll('.slide__title')[0].offsetHeight;
      const slideCtaHeight =
        slideTop.querySelectorAll('.slide__cta')[0].offsetHeight;
      let activeHeight = 0;

      slideTop.style.height = '100% !important';

      // height of container is determined by child content height
      if (slideTitleHeight > slideCtaHeight) {
        activeHeight += slideTitleHeight;
      } else {
        activeHeight += slideCtaHeight;
      }

      if (window.innerWidth > 1024) {
        // add extra height to account for vertical padding on LG screens
        activeHeight += 111;
      } else {
        // add extra height to account for vertical padding on MD screens
        activeHeight += 79;
      }

      slideTop.removeAttribute('style');
      slideTop.style.height = `${activeHeight}px`;
    });
  }

  function fireResizeEventToSetProperSlideTopHeight() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  // Render carousel
  function renderCarousels() {
    $('.aoi__carousel--mobile').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
    });

    $('.aoi__carousel--desktop').slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      draggable: false,
    });

    const prevBtn = document.createElement('button');
    prevBtn.classList.add('slide__button', 'slide__button--prev');
    prevBtn.innerHTML = `<span class="slide__button-icon" aria-hidden="true"></span><span class="visually-hidden">Previous</span>`;
    const nextBtn = document.createElement('button');
    nextBtn.classList.add('slide__button', 'slide__button--next');
    nextBtn.innerHTML = `<span class="slide__button-icon" aria-hidden="true"></span><span class="visually-hidden">Next</span>`;

    const desktopCarousel = document.querySelectorAll(
      '.aoi__carousel--desktop',
    )[0];
    const desktopNavButtons = document.querySelectorAll('.aoi__dots button');

    desktopCarousel.prepend(prevBtn);
    desktopCarousel.append(nextBtn);

    prevBtn.addEventListener('click', () => {
      const currentActiveSlide = desktopCarousel.querySelectorAll(
        '.slick-slide.slick-active',
      )[0];
      const currentActiveNavButton = document.querySelectorAll(
        '.aoi__dots button[aria-current]',
      )[0];

      desktopCarousel.classList.toggle('js-transition');

      setTimeout(() => {
        currentActiveNavButton.removeAttribute('aria-current');

        $('.aoi__carousel--desktop').slick('slickPrev');

        const newActiveSlide = desktopCarousel.querySelectorAll(
          '.slick-slide.slick-active',
        )[0];
        const newSlideIndex = newActiveSlide.dataset.slickIndex;
        const newActiveNavButton = document.querySelectorAll(
          `.aoi__dots button[data-corresponding-slide="${newSlideIndex}"]`,
        )[0];

        currentActiveSlide.removeAttribute('height');
        newActiveSlide.style.height = newActiveSlide.dataset.activeHeight;

        // eslint-disable-next-line max-nested-callbacks
        setTimeout(() => {
          desktopCarousel.classList.remove('js-transition');
          newActiveNavButton.setAttribute('aria-current', 'true');
        }, 300);
      }, 900);
    });

    nextBtn.addEventListener('click', () => {
      const currentActiveSlide = desktopCarousel.querySelectorAll(
        '.slick-slide.slick-active',
      )[0];
      const currentSlideTop =
        currentActiveSlide.querySelectorAll('.slide__top')[0];
      const currentActiveNavButton = document.querySelectorAll(
        '.aoi__dots button[aria-current]',
      )[0];

      desktopCarousel.classList.toggle('js-transition');

      setTimeout(() => {
        currentActiveNavButton.removeAttribute('aria-current');

        $('.aoi__carousel--desktop').slick('slickNext');

        const newActiveSlide = desktopCarousel.querySelectorAll(
          '.slick-slide.slick-active',
        )[0];
        const newSlideTop = newActiveSlide.querySelectorAll('.slide__top')[0];
        const newSlideIndex = newActiveSlide.dataset.slickIndex;
        const newActiveNavButton = document.querySelectorAll(
          `.aoi__dots button[data-corresponding-slide="${newSlideIndex}"]`,
        )[0];

        currentSlideTop.removeAttribute('height');
        newSlideTop.style.height = `${newSlideTop.dataset.activeHeight}px`;

        // eslint-disable-next-line max-nested-callbacks
        setTimeout(() => {
          desktopCarousel.classList.remove('js-transition');
          newActiveNavButton.setAttribute('aria-current', 'true');
        }, 300);
      }, 900);
    });

    desktopNavButtons.forEach((navButton) => {
      navButton.addEventListener('click', (e) => {
        const clicked = e.target;
        const selectedIndex = clicked.dataset.correspondingSlide;
        const currentActiveNavButton = document.querySelectorAll(
          '.aoi__dots button[aria-current]',
        )[0];
        const currentSlide = desktopCarousel.querySelectorAll(
          '.slick-slide.slick-active',
        )[0];
        const currentSlideIndex = currentSlide.dataset.slickIndex;
        const currentSlideTop = currentSlide.querySelectorAll('.slide__top')[0];

        if (selectedIndex === currentSlideIndex) {
          return;
        }

        desktopCarousel.classList.toggle('js-transition');

        // eslint-disable-next-line max-nested-callbacks
        setTimeout(() => {
          currentActiveNavButton.removeAttribute('aria-current');

          $('.aoi__carousel--desktop').slick('slickGoTo', selectedIndex);

          const newActiveSlide = desktopCarousel.querySelectorAll(
            '.slick-slide.slick-active',
          )[0];

          const newSlideTop = newActiveSlide.querySelectorAll('.slide__top')[0];

          currentSlideTop.removeAttribute('height');
          newSlideTop.style.height = `${newSlideTop.dataset.activeHeight}px`;

          // eslint-disable-next-line max-nested-callbacks
          setTimeout(() => {
            desktopCarousel.classList.remove('js-transition');
            clicked.setAttribute('aria-current', 'true');
          }, 300);
        }, 900);
      });
    });
  }

  function focusLoop() {
    const activeDialog = document.querySelectorAll('.dialog.js-active')[0];
    activeDialog.querySelectorAll('.dialog__close')[0].focus();

    document.addEventListener('keydown', (e) => {
      const firstFocusableElement =
        activeDialog.querySelectorAll('.dialog__close')[0];
      const lastFocusableElement = activeDialog.querySelectorAll(
        '.dialog__control--right',
      )[0];
      const focused = document.activeElement;
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        // shift + tab was pressed - looping through items backwards
        if (focused === firstFocusableElement) {
          setTimeout(() => {
            lastFocusableElement.focus();
          }, 50);
        }
      } else {
        // tab was pressed - looping through items forwards
        // eslint-disable-next-line no-lonely-if
        if (focused === lastFocusableElement) {
          setTimeout(() => {
            firstFocusableElement.focus();
          }, 50);
        }
      }
    });
  }

  function closeDialogViaKeyboardNavigation() {
    document.addEventListener('keydown', (e) => {
      const activeDialog = document.querySelectorAll('.dialog.js-active');
      const isEscapePressed = e.key === 'Escape' || e.keyCode === 27;
      const body = document.querySelectorAll('body')[0];
      const html = document.querySelectorAll('html')[0];

      if (activeDialog.length === 0 || !isEscapePressed) {
        return;
      }

      const aoiButton = document.querySelectorAll(
        `.slick-slide:not(.slick-cloned) .aoi__button[aria-controls="${activeDialog[0].getAttribute(
          'id',
        )}"]`,
      )[0];

      activeDialog[0].classList.remove('js-active');
      body.classList.remove('js-fixed');
      html.classList.remove('js-fixed');

      setTimeout(() => {
        aoiButton.removeAttribute('tabindex');
        aoiButton.focus();
      }, 150);
    });
  }

  function openDialog(e) {
    const button = e.target;
    const dialogSelector = button.getAttribute('aria-controls');
    const dialog = document.querySelectorAll(`#${dialogSelector}`)[0];
    const body = document.querySelectorAll('body')[0];
    const html = document.querySelectorAll('html')[0];

    dialog.classList.add('js-active');
    body.classList.add('js-fixed');
    html.classList.add('js-fixed');

    setTimeout(() => {
      focusLoop();
      closeDialogViaKeyboardNavigation();
    }, 300);
  }

  function closeDialog(e) {
    const button = e.target;
    const dialog = button.parentElement.parentElement.parentElement;
    const body = document.querySelectorAll('body')[0];
    const html = document.querySelectorAll('html')[0];
    const aoiButton = document.querySelectorAll(
      `.slick-slide:not(.slick-cloned) .aoi__button[aria-controls="${dialog.getAttribute(
        'id',
      )}"]`,
    )[0];

    dialog.classList.remove('js-active');
    body.classList.remove('js-fixed');
    html.classList.remove('js-fixed');

    setTimeout(() => {
      aoiButton.removeAttribute('tabindex');
      aoiButton.focus();
    }, 150);
  }

  function switchDialog(e) {
    const button = e.target;
    const dialog = button.parentElement.parentElement;
    const currentDialogIndex = parseInt(dialog.dataset.index, 10);
    const maxDialogIndex = parseInt(
      document.querySelectorAll('.dialog:last-of-type')[0].dataset.index,
      10,
    );
    let newDialogIndex;

    if (button.classList.contains('dialog__control--left')) {
      newDialogIndex = currentDialogIndex - 1;

      if (newDialogIndex === 0) {
        newDialogIndex = maxDialogIndex;
      }
    } else if (button.classList.contains('dialog__control--right')) {
      newDialogIndex = currentDialogIndex + 1;

      if (newDialogIndex > maxDialogIndex) {
        newDialogIndex = 1;
      }
    }

    const newDialog = document.querySelectorAll(
      `.dialog[data-index="${newDialogIndex}"]`,
    )[0];

    dialog.classList.remove('js-active');
    newDialog.classList.add('js-active');

    $('.aoi__carousel--mobile').slick('slickGoTo', newDialogIndex - 1);

    setTimeout(() => {
      focusLoop();
      closeDialogViaKeyboardNavigation();
    }, 300);
  }

  const init = () => {
    if (!$('.aoi').length) {
      return;
    }

    renderCarousels();
    $('.aoi__button').on('click', openDialog);
    $('.dialog__close').on('click', closeDialog);
    $('.dialog__control').on('click', switchDialog);
    $(window).on('resize', setSlideTopHeight);
    $(document).ready(fireResizeEventToSetProperSlideTopHeight);
  };

  return {
    init,
  };
})();

export default areasOfInterest;
