const programsFilter = (() => {
  let $programsSection, $filterInputs, $programCards;

  const defineComponentVariables = () => {
    $programsSection = $('.section.program-types');
    $filterInputs = $('.section.program-types').find(
      '.program-types__filter-input',
    );
    $programCards = $programsSection.find('.program-card');
  };

  const addCardAnimationDelay = () => {
    let delayTime = 0;
    $programCards.each((index, card) => {
      delayTime += 0.15;
      $(card).css({ 'transition-delay': `${delayTime}s` });
    });
  };

  const applyFilters = () => {
    const $checkedFilters = $programsSection.find(
      '.program-types__filter-input:checked',
    );

    const filters = [];

    $checkedFilters.each((index, item) => {
      filters.push($(item).siblings('.type').text().toLowerCase());
    });

    $programCards.each((index, card) => {
      const $card = $(card);
      const labels = [];

      $card.find('.program-card__label').each((index, label) => {
        labels.push($(label).text().toLowerCase());
      });

      if (labels.some((label) => filters.includes(label))) {
        $card.show().attr('aria-hidden', 'false');
      } else {
        $card.hide().attr('aria-hidden', 'true');
      }
    });
  };

  function addFocusState(e) {
    const $elem = $(e.target);

    $elem.parent().addClass('js-focus');
  }

  function removeFocusState(e) {
    const $elem = $(e.target);

    $elem.parent().removeClass('js-focus');
  }

  const init = () => {
    defineComponentVariables();
    applyFilters();
    addCardAnimationDelay();

    $('.program-types__filter-input').on('focus', addFocusState);

    $('.program-types__filter-input').on('focusout', removeFocusState);

    $filterInputs.on('change', () => {
      applyFilters();
    });
  };

  return {
    init,
  };
})();

export default programsFilter;
