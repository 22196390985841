/**
 * Video Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Video Carousel is present
 */

/* global YT */

const videoCarousel = (() => {
  function render() {
    document.querySelectorAll('.video-carousel').forEach((elem) => {
      $(elem).on('init', (slick) => {
        const clonedSlides = elem.querySelectorAll('.slick-slide.slick-cloned');

        // eslint-disable-next-line max-nested-callbacks
        clonedSlides.forEach((clonedSlide) => {
          const videoCover = clonedSlide.querySelectorAll('.vid-inline__cover');

          if (videoCover.length) {
            videoCover[0].setAttribute('tabindex', '-1');
          }
        });
      });

      $(elem)
        .find('.carousel-top')
        .slick({
          dots: false,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          arrows: false,
          asNavFor: $(elem).find('.carousel-bottom__list'),
          lazyLoad: 'ondemand',
          instructionsText:
            'The videos in this carousel are changed using the thumbnails carousel that follows it.',
        });

      $(elem)
        .find('.carousel-bottom__list')
        .slick({
          accessibility: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: $(elem).find('.carousel-top'),
          appendArrows: $(elem).find('.carousel-bottom-controller'),
          variableWidth: true,
          focusOnSelect: true,
          centerMode: true,
          arrows: true,
          lazyLoaded: true,
          instructionsText:
            'Changing the current slide of this carousel changes the video of the preceding carousel',
        });

      $(elem).find('.carousel-top').attr('aria-label', 'main video carousel');

      $(elem)
        .find('.carousel-bottom__list')
        .attr('aria-label', 'thumbnails carousel');

      $(elem)
        .find('.carousel-bottom__list .slick-slide')
        .attr('role', 'button');

      // Pause video if playing during slide change
      $(elem).on('beforeChange', (e) => {
        const id = $('.carousel-top .slick-current iframe').attr('id');

        if (id !== undefined) {
          const player = YT.get(id);
          if (player.getPlayerState() === 1) {
            player.pauseVideo();
          }
        }
      });

      $(elem).on('afterChange', (slick, currentSlide) => {
        const prevSlideCover = elem.querySelectorAll(
          '.vid-inline__cover[tabindex="0"]',
        )[0];
        const currSlide = elem.querySelectorAll(
          `.slick-slide[data-slick-index="${currentSlide.currentSlide}"]`,
        )[0];
        const currSlideCover =
          currSlide.querySelectorAll('.vid-inline__cover')[0];

        prevSlideCover.setAttribute('tabindex', '-1');
        currSlideCover.setAttribute('tabindex', '0');
        $(elem).find('.carousel-bottom__list .slick-slide').attr('tabindex', 0);
      });
      $(elem)
        .find('.carousel-bottom__list .slick-slide')
        .on('click keypress', (e) => {
          e.stopPropagation();
          if (e.type === 'click' || e.key === 'Enter') {
            const $attr = $(e.currentTarget).attr('data-slick-index');
            $(elem).find('.carousel-bottom__list').slick('slickGoTo', $attr);
          }
        });
    });
  }

  const init = () => {
    if (!$('.video-carousel').length) {
      return;
    }

    render();
  };

  return {
    init,
  };
})();

export default videoCarousel;
