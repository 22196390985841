/**
 * News Embed display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if News Embed is present
 */

const newsEmbed = (() => {
  function render() {
    document.querySelectorAll('.news-embed').forEach((elem) => {
      $(elem)
        .find('.news-embed__items')
        .slick({
          dots: false,
          infinite: false,
          speed: 300,
          arrows: true,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          adaptiveHeight: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: 'unslick',
            },
            {
              breakpoint: 699,
              settings: {
                slidesToShow: 2,
              },
            },
          ],
        });
    });
  }

  function renderResize() {
    if (
      $(window).width() <= 1024 &&
      !$('.news-embed__items').hasClass('slick-initialized')
    ) {
      render();
    }
  }

  const init = () => {
    if (!$('.news-embed').length) {
      return;
    }

    render();

    $(window).on('load, resize', renderResize);
  };

  return {
    init,
  };
})();

export default newsEmbed;
