const faqDropdowns = (() => {
  const OPENED_CLASS_NAME = 'is-opened';
  const ANIMATION_SPEED = 300;

  const init = () => {
    const $items = $(
      '.questions--tablet .questions__item-content, .questions--desktop .questions__item-content',
    );

    $items.each((index, questionBox) => {
      const $questionBox = $(questionBox);
      const $questionOpener = $questionBox.find('.questions__question');
      const $answer = $questionBox.find('.questions__answer');
      const $questionClose = $answer.find('.close-faq-modal');

      $questionOpener.on('click', () => {
        $questionBox.get(0).classList.toggle(OPENED_CLASS_NAME);

        if ($questionBox.get(0).classList.contains(OPENED_CLASS_NAME)) {
          $questionOpener.attr('aria-expanded', 'true');
          $answer.slideDown(ANIMATION_SPEED).attr('aria-hidden', 'false');
        } else {
          $questionOpener.attr('aria-expanded', 'false');
          $answer.slideUp(ANIMATION_SPEED).attr('aria-hidden', 'true');
        }
      });

      $questionClose.on('click', (e) => {
        const clicked = e.target;
        const questionOpenerToFocus =
          clicked.parentElement.previousElementSibling;

        $questionBox.removeClass(OPENED_CLASS_NAME);
        $questionOpener.attr('aria-expanded', 'false');
        $answer.slideUp(ANIMATION_SPEED).attr('aria-hidden', 'true');
        questionOpenerToFocus.focus();
      });
    });
  };

  return {
    init,
  };
})();

export default faqDropdowns;
