/**
 * Blog 3 Up display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Blog 3 Up is present
 */

const blog3Up = (() => {
  function render() {
    document.querySelectorAll('.blog3up').forEach((elem) => {
      $(elem)
        .find('.blog3up__items')
        .slick({
          dots: false,
          infinite: false,
          speed: 300,
          arrows: true,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          adaptiveHeight: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: 'unslick',
            },
            {
              breakpoint: 699,
              settings: {
                slidesToShow: 2,
              },
            },
          ],
        });
    });
  }

  function renderResize() {
    if (
      $(window).width() <= 1024 &&
      !$('.blog3up__items').hasClass('slick-initialized')
    ) {
      render();
    }
  }

  const init = () => {
    if (!$('.blog3up').length) {
      return;
    }

    render();

    $(window).on('load, resize', renderResize);
  };

  return {
    init,
  };
})();

export default blog3Up;
