/* eslint-disable prefer-destructuring */
/**
 * Sticky CTAs display object interactions
 * @param {*} $
 * @returns {init} Determine and run if Sticky CTAs are present
 */

const stickyCTAs = (() => {
  function collapseCTAsWhenScrollingPastHero() {
    // eslint-disable-next-line no-shadow
    const stickyCTAs = document.querySelectorAll('.sticky-ctas')[0];
    const hero = document.querySelectorAll('.hero')[0];
    let haveCTAsCollapsed = false;

    window.addEventListener('scroll', () => {
      if (window.scrollY > hero.offsetHeight && haveCTAsCollapsed === false) {
        if (!stickyCTAs.classList.contains('js-collapsed')) {
          stickyCTAs.classList.add('js-collapsed');
        }

        haveCTAsCollapsed = true;
      }
    });
  }

  function offsetPositioningWhenFooterIsInView() {
    // eslint-disable-next-line no-shadow
    const stickyCTAs = document.querySelectorAll('.sticky-ctas')[0];
    const footer = document.querySelectorAll('.main-footer')[0];

    window.addEventListener('scroll', () => {
      let originalBottomPosition;

      if (window.innerWidth < 700) {
        originalBottomPosition = 10;
      } else if (window.innerWidth < 1025) {
        originalBottomPosition = 19;
      } else {
        originalBottomPosition = 28;
      }

      const offset =
        window.scrollY +
        window.innerHeight -
        footer.offsetTop +
        originalBottomPosition;

      if (window.scrollY > footer.offsetTop - window.innerHeight) {
        stickyCTAs.style.bottom = `${offset}px`;

        if (stickyCTAs.classList.contains('js-collapsed')) {
          stickyCTAs.classList.remove('js-collapsed');
        }
      } else {
        stickyCTAs.classList.add('js-collapsed');
        stickyCTAs.removeAttribute('style');
      }
    });
  }

  function toggleStickyCTAs(e) {
    const clicked = e.target;
    const visuallyHiddenButtonText =
      clicked.querySelectorAll('.visually-hidden')[0];
    const collapsedCTAs = clicked.parentElement.querySelectorAll(
      '.sticky-ctas__wrapper > .cta',
    );

    clicked.parentElement.classList.toggle('js-collapsed');

    if (clicked.parentElement.classList.contains('js-collapsed')) {
      $('.sticky-ctas__toggle').attr('aria-expanded', false);
      collapsedCTAs.forEach((cta) => {
        cta.setAttribute('tabindex', '-1');
      });
    } else {
      $('.sticky-ctas__toggle').attr('aria-expanded', true);
      collapsedCTAs.forEach((cta) => {
        cta.removeAttribute('tabindex');
      });
    }
  }

  const init = () => {
    if (!$('.sticky-ctas').length) {
      return;
    }

    collapseCTAsWhenScrollingPastHero();
    offsetPositioningWhenFooterIsInView();
    $('.sticky-ctas__toggle').on('click', toggleStickyCTAs);
  };

  return {
    init,
  };
})();

export default stickyCTAs;
