const mainHeader = (() => {
  let $window;
  let $body;
  let $htmlBody;
  let $mainHeader;

  const ANIMATION_SPEED = 300;
  const MAIN_NAV_OPENED_CLASS_NAME = 'main-nav-opened';
  const HEADER_HIDDEN_CLASS_NAME = 'header-hidden';
  const HEADER_STICKY_CLASS_NAME = 'sticky-header';

  const setGlobalVariables = () => {
    $window = $(window);
    $body = $('html > body');
    $htmlBody = $('html, html > body');
    $mainHeader = $('.main-header');
  };

  const initMainNavOpener = () => {
    const $mainNavOpener = $('.main-nav-opener');
    const $mainNavHolder = $('.nav__holder');

    $mainNavOpener.on('focus', () => {
      $('.main-header').removeClass('header-hidden');
    });

    $mainNavOpener.on('click', () => {
      if (!$body.hasClass(MAIN_NAV_OPENED_CLASS_NAME)) {
        $htmlBody.addClass(MAIN_NAV_OPENED_CLASS_NAME);
        $mainNavOpener
          .attr('aria-expanded', 'true')
          .find('.text')
          .text('Close');
        $mainNavHolder.attr('aria-hidden', 'false');

        if ($('header').hasClass('js-alert-positioned')) {
          $('.alert').slideUp();
        }
      } else {
        $htmlBody.removeClass(MAIN_NAV_OPENED_CLASS_NAME);
        $mainNavOpener
          .attr('aria-expanded', 'false')
          .find('.text')
          .text('Menu');
        $mainNavHolder.attr('aria-hidden', 'true');
        $('.alert').slideDown();
      }
    });

    $(document).on('click', (e) => {
      if (!$(e.target).closest('.main-nav__box').length) {
        $htmlBody.removeClass(MAIN_NAV_OPENED_CLASS_NAME);
        $mainNavOpener
          .attr('aria-expanded', 'false')
          .find('.text')
          .text('Menu');
        $mainNavHolder.attr('aria-hidden', 'true');
      }
    });

    $(document).on('keydown', (e) => {
      if (e.keyCode === 27) {
        $htmlBody.removeClass(MAIN_NAV_OPENED_CLASS_NAME);
        $mainNavOpener
          .attr('aria-expanded', 'false')
          .find('.text')
          .text('Menu');
        $mainNavHolder.attr('aria-hidden', 'true');
      }
    });
  };

  const initSubNavControls = () => {
    const $submenuOpeners = $(
      '.main-submenu__opener, .secondary-submenu__opener',
    );

    $submenuOpeners.each((index, item) => {
      const OPENED_SUBMENU_CLASS_NAME = 'is-opened';
      const $opener = $(item);
      const $submenu = $opener.siblings('.submenu');

      $opener.on('click', () => {
        if (!$opener.hasClass(OPENED_SUBMENU_CLASS_NAME)) {
          $opener
            .addClass(OPENED_SUBMENU_CLASS_NAME)
            .attr('aria-expanded', 'true');

          $submenu
            .stop()
            .slideDown(ANIMATION_SPEED)
            .attr('aria-hidden', 'false');
        } else {
          $opener
            .removeClass(OPENED_SUBMENU_CLASS_NAME)
            .attr('aria-expanded', 'false');

          $submenu.stop().slideUp(ANIMATION_SPEED).attr('aria-hidden', 'true');
        }
      });
    });
  };

  const initMenuKeyboardControls = () => {
    const $lastMenuFocusableElement = $(
      '.nav__holder a, .nav__holder button',
    ).last();
    const $navOpener = $('.main-nav-opener');

    $lastMenuFocusableElement.on('keydown', (event) => {
      if (!event.shiftKey && event.keyCode === 9) {
        event.preventDefault();
        $navOpener.focus();
      }
    });

    $navOpener.on('keydown', (event) => {
      if (
        event.shiftKey &&
        event.keyCode === 9 &&
        $body.hasClass(MAIN_NAV_OPENED_CLASS_NAME)
      ) {
        event.preventDefault();
        $lastMenuFocusableElement.focus();
      }
    });
  };

  const initHeaderBehavior = () => {
    let lastScrollPos = 0;
    let currentScrollPos = 0;
    const $alertHeight = $('.alert-display').outerHeight() || 0;

    $window.on('scroll load', () => {
      currentScrollPos = $(window).scrollTop();

      if (currentScrollPos > $mainHeader.outerHeight() + $alertHeight) {
        $mainHeader.addClass(HEADER_STICKY_CLASS_NAME);
        if (lastScrollPos < currentScrollPos && $window.scrollTop() > 50) {
          $mainHeader.addClass(HEADER_HIDDEN_CLASS_NAME);
        } else {
          $mainHeader.removeClass(HEADER_HIDDEN_CLASS_NAME);
        }
      } else {
        $mainHeader.removeClass(HEADER_STICKY_CLASS_NAME);
      }

      lastScrollPos = currentScrollPos;
    });
  };

  const showHeader = () => {
    if (!$('.main-header').hasClass('header-hidden')) {
      return;
    }

    $('.main-header').removeClass('header-hidden')
  };

  const init = () => {
    setGlobalVariables();

    setTimeout(() => {
      // add 'content-loaded' class name to body tag with timeout in case 'window-load' event will not fire
      $body.addClass('content-loaded');
    }, 500);

    $window.on('load', () => {
      // add 'content-loaded' class name to body tag to prevent header jumping-effect when page is loaded ->
      // -> and to prevent main-menu closed position animation
      $body.addClass('content-loaded');
    });

    $('header a, header button').on('focus', e => {
      showHeader();
    });

    initMainNavOpener();
    initSubNavControls();
    initMenuKeyboardControls();
    initHeaderBehavior();
  };

  return {
    init,
  };
})();

export default mainHeader;
