import lastWord from '../util/last-word';

/* eslint-disable global-require */
const facultyTable = (() => {
  require('datatables.net-dt')();
  require('datatables.net-buttons')();

  const renameLabel = (type) => {
    if (type.toUpperCase() === 'NAME & TITLE') {
      return 'type';
    }

    if (type.toUpperCase() === 'OFFICE LOCATION') {
      return 'campusLocation';
    }

    return type.toLowerCase();
  };

  const directoryInit = () => {
    const $tableHolder = $('#faculty-table');
    const ajaxPath = $tableHolder[0].dataset.url;

    function renderTable(sortedData) {
      const table = $tableHolder
        .DataTable({
          data: sortedData,
          processing: true,
          pagingType: 'simple_numbers',
          sPaginationType: 'ellipses',
          deferLoading: 57,
          ordering: false,

          language: {
            search: '<span class="search-title">Search Within Table</span>',
            searchPlaceholder: 'Type to Filter by Term',
            infoFiltered: '',
            infoEmpty: '',
            info: `<strong>_START_-_END_</strong>
                <span>of</span>
                <strong>_TOTAL_</strong>`,
            sLengthMenu: `<div class="table-length-wrap">
              <div class="form--select__wrapper">
                <select name="table-length" class="table-length form__control">
                  <option value="10">Show <span>10</span></option>
                  <option value="20">Show <span>20</span></option>
                  <option value="30">Show <span>30</span></option>
                  <option value="40">Show <span>40</span></option>
                  <option value="50">Show <span>50</span></option>
                  <option value="-1">All</option>
                </select>
              </div>
              <span>per page</span>
            </div>`,
          },
          columns: [
            {
              data: 'type',
            },
            {
              data: 'department',
            },
            {
              data: 'email',
            },
            {
              data: 'phone',
            },
            {
              data: 'campusLocation',
            },
          ],
          columnDefs: [
            {
              targets: 0,
              data: 'type',
              render(data, type, row) {
                if (row.url) {
                  return `<div class="row-section">
                  <span class="row-label">Name &amp; Title</span>
                  <div class="row-content">
                  <a class="link-name" href="${row.url}">${row.displayName}</a>
                  <span class="title">${row.title}</span>
                  <span class="type">${row.type}</span>
                  </div>
                  </div>`;
                }
                return `<div class="row-section">
                  <span class="row-label">Name &amp; Title</span>
                  <div class="row-content">
                  <span class="link-name" >${row.displayName}</span>
                  <span class="title">${row.title}</span>
                  <span class="type">${row.type}</span>
                  </div>
                  </div>`;
              },
            },
            {
              targets: 1,
              data: 'department',
              render(data) {
                return `<div class="row-section"><span class="row-label">Department</span><span class="department">${data}</span></div>`;
              },
            },
            {
              targets: 2,
              data: 'email',
              render(data, type, row) {
                return row.email
                  ? `<div class="row-section"><span class="row-label">Email</span><a class="email" href="mailto:${row.email}">${data}</a></div>`
                  : '';
              },
            },
            {
              targets: 3,
              data: 'phone',
              render(data, type, row) {
                return row.phone
                  ? `<div class="row-section"><span class="row-label">Phone</span><span class="tel">${data}</span></div>`
                  : '';
              },
            },
            {
              targets: 4,
              data: 'campusLocation',
              render(data, type, row) {
                return `<div class="row-section"><span class="row-label">Location</span><span class="campusLocation">${data}</span></div>`;
              },
            },
            {
              targets: '_all',
              orderable: false,
            },
          ],
          dom: '<"heading-table"B<"heading-table-filter"<"mobile-filter-drop"<"toolbar">rf>>><"wrapper-table table-block__wrapper"<"table-holder"t>><"footer-table"p<"table-info"li>>',
          keys: true,
          responsive: true,
          initComplete() {
            this.api()
              .columns()
              // eslint-disable-next-line array-callback-return
              .every(function (i) {
                const column = this;
                const info = this.page.info();
                const toolbar = $('.toolbar');
                const holder = document.createElement('div');
                const label = document.createElement('label');
                const searchHolder = $('#faculty-table_filter');
                const searchLabel = searchHolder
                  .get(0)
                  .querySelectorAll('label')[0];
                const select = $(
                  '<select class="form__control filter"><option value="">All</option></select>',
                );
                const searchIcon = $(`
                  <span aria-hidden="true">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.46 18L11.33 13.2533C10.78 13.7064 10.1475 14.0651 9.4325 14.3294C8.7175 14.5938 7.95667 14.7259 7.15 14.7259C5.15167 14.7259 3.4606 14.0134 2.0768 12.5884C0.692267 11.1626 0 9.4208 0 7.36295C0 5.3051 0.692267 3.56329 2.0768 2.13752C3.4606 0.712507 5.15167 0 7.15 0C9.14833 0 10.8398 0.712507 12.2243 2.13752C13.6081 3.56329 14.3 5.3051 14.3 7.36295C14.3 8.19365 14.1717 8.97714 13.915 9.71344C13.6583 10.4497 13.31 11.1011 12.87 11.6674L18 16.4141L16.46 18ZM7.15 12.4604C8.525 12.4604 9.69393 11.965 10.6568 10.9742C11.6189 9.98265 12.1 8.77891 12.1 7.36295C12.1 5.947 11.6189 4.74325 10.6568 3.75171C9.69393 2.76092 8.525 2.26552 7.15 2.26552C5.775 2.26552 4.60607 2.76092 3.6432 3.75171C2.68107 4.74325 2.2 5.947 2.2 7.36295C2.2 8.77891 2.68107 9.98265 3.6432 10.9742C4.60607 11.965 5.775 12.4604 7.15 12.4604Z" fill="currentColor"/>
                    </svg>
                  </span>
                  `).get(0);

                $(label).text(renameLabel(this.header().innerText));

                $(label)
                  .addClass('form__label')
                  .attr('for', `input-${i}`)
                  .appendTo(holder);
                select.attr('id', `input-${i}`).appendTo(holder);
                $(holder).addClass('filter-box').appendTo(toolbar);
                searchHolder.appendTo(toolbar);
                select.wrap(`<div class="form--select__wrapper"></div>`);

                if (!searchLabel.querySelectorAll('span[aria-hidden]').length) {
                  searchLabel.append(searchIcon);
                }

                select.on('change', function () {
                  const val = $.fn.dataTable.util.escapeRegex($(this).val());

                  if (column[0][0] === 1) {
                    column
                      .search(val ? `${val}$` : val, true, true, false)
                      .draw();
                  } else {
                    column
                      .search(val ? `${val}` : val, true, true, false)
                      .draw();
                  }
                });

                column
                  .data()
                  .unique()
                  .sort()
                  .each(function (d, j) {
                    select.append(`<option value="${d}">${d}</option>`);
                  });
              });

            $('.dataTables_paginate.paging_simple_numbers').attr({
              role: 'navigation',
              'aria-label': 'pagination',
            });
            $('.dataTables_paginate .paginate_button').attr('role', 'button');
            $('.dataTables_paginate a.current').attr('aria-current', 'true');
            $('.dataTables_paginate .paginate_button.disabled').attr(
              'disabled',
              'disabled',
            );
          },
        })
        .on('draw', function () {
          $('.dataTables_paginate a.current').attr('aria-current', 'true');
          $('.dataTables_paginate .paginate_button.disabled').attr(
            'disabled',
            'disabled',
          );
        });

      const renderBtnClear = (container) => {
        const btnClear = document.createElement('button');
        const iconClear = document.createElement('i');
        const filterHolder = $(container);
        $(btnClear).addClass('clear-table').html('<span>Reset filter</span>');
        $(iconClear).addClass('icon icon-refresh').attr('aria-hidden', 'true');
        $(iconClear).appendTo(btnClear);
        $(btnClear).insertAfter(filterHolder);
      };

      const resetFilter = () => {
        $('.clear-table').on('click', function (e) {
          e.preventDefault();
          $('.filter').prop('selectedIndex', 0);
          table.search('').columns().search('').draw();
        });
      };

      renderBtnClear('.dataTables_filter');
      resetFilter();
    }

    $.getJSON(ajaxPath, (json) => {
      const arr = json;

      // Sort array alphabetically before rendering table
      arr.data.sort(function sortResults(a, b) {
        const nameA = a.lastName.toUpperCase();
        const nameB = b.lastName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      renderTable(arr.data);
    });
  };

  const facultyTableWatcher = () => {
    // watches for any clicks on .paginate_button to check if we should run addLastWordSpan() again
    document.addEventListener('click', (e) => {
      const clicked = e.target;

      if (clicked.classList.contains('paginate_button')) {
        lastWord.addLastWordSpan('a.link-name', 150);
      }
    });
  };

  const init = () => {
    if ($('#faculty-table').length) {
      directoryInit();
      facultyTableWatcher();
    }
  };

  return {
    init,
  };
})();

export default facultyTable;
